import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import { Container, Grid, Divider} from "@material-ui/core";
import FixedNavbar from "../components/FixedNavbar";
import Footer from "../components/Footer";
import showdown from 'showdown';

// eslint-disable-next-line 
export const PrivacyPolicyPageTemplate = ({ 
    heading, content
 }) => {

  const converter = new showdown.Converter();

  return (
    <>
      <FixedNavbar />
        <section className="visit" style={{marginTop:"60px"}}>
          <Container maxWidth="md">
            <Grid container spacing={2} className="alignContentCenter">
              <Grid item xs={12}>
                <Divider className="sectionHeaderLine" />
                <h2>{heading}</h2>
                <br/>
                <div className="bodyText" dangerouslySetInnerHTML={{ __html: converter.makeHtml(content) }} />
              </Grid>
             </Grid>
          </Container>
        </section>
      <Footer />
    </>
  );
};

PrivacyPolicyPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const PrivacyPolicyPage = ({ data }) => {
  // const { markdownRemark: post } = data;

  return (
    <Layout>
      {/* <PrivacyPolicyPageTemplate 
       heading={post.frontmatter.heading}
       subheading={post.frontmatter.subheading}
       content={post.frontmatter.content}
       /> */}
    </Layout>
  );
};

PrivacyPolicyPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default PrivacyPolicyPage;

export const PrivacyPolicyPageQuery = graphql`
  query PrivacyPolicyPageTemplate  {
    markdownRemark(frontmatter: {templateKey: {eq: "privacypolicy-page"}}) {
      frontmatter {
       heading,
       subheading,
       content
      }
    }
  }
`;